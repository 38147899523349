<template>
    <div>
        <b-modal v-model="showModal" @hidden="$emit('close')">
            <template v-slot:modal-header>
                <div class="w-100 d-flex justify-content-between align-items-center py-2">
                    <div class="d-flex align-items-center">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'16'" v-bind:width="'16'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18.497 10.673c-1.024 2.351-3.79 3.527-8.298 3.527H8.075v2.24c0 .152-.06.283-.18.394a.608.608 0 01-.427.166.608.608 0 01-.427-.166l-4.856-4.48a.519.519 0 010-.788l4.856-4.48a.61.61 0 01.427-.167c.164 0 .306.056.427.167.12.11.18.242.18.394v2.24h2.124c.62 0 1.174-.018 1.664-.053s.977-.097 1.46-.188c.484-.09.905-.214 1.262-.372.357-.157.69-.36 1-.608.31-.248.564-.542.76-.884.196-.341.349-.745.46-1.212.11-.466.166-.994.166-1.583 0-.321-.016-.68-.048-1.077 0-.034-.008-.103-.024-.205a1.666 1.666 0 01-.023-.232c0-.087.027-.16.08-.219A.289.289 0 0117.18 3c.101 0 .19.05.266.149a.984.984 0 01.123.192 14.621 14.621 0 01.228.473C18.599 5.476 19 6.79 19 7.76c0 1.16-.168 2.132-.503 2.913z" fill="#555775"/></svg>
                        <span class="ml-2">{{staticText.shareCallLabel}}</span>
                    </div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'16'" v-bind:width="'16'" v-bind:class="'cursor-pointer'" v-bind:svg-inline="''" @click="closeShareModal" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 1.6C4.48 1.6 1.6 4.48 1.6 8c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4 0-3.52-2.88-6.4-6.4-6.4zm3.2 8.704l-.896.896L8 8.896 5.696 11.2l-.896-.896L7.104 8 4.8 5.696l.896-.896L8 7.104 10.304 4.8l.896.896L8.896 8l2.304 2.304z" fill="#AAABBA"/></svg>

                </div>
              </template>
              <template v-slot:default>
                <div>
                    <div class="about-share px-3">
                        <div @click="showAboutShare=!showAboutShare" class="d-flex justify-content-between align-items-center py-3 cursor-pointer">
                            <div class="d-flex about-icon">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'20'" v-bind:width="'20'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm8.167-3.674l-.01-.01a.543.543 0 01-.129-.362v-.778c0-.135.042-.26.13-.362l.004-.005.005-.005a.513.513 0 01.372-.154h.907c.144 0 .271.053.372.154a.513.513 0 01.154.372v.778c0 .143-.052.27-.154.372l-.004.005-.006.004a.543.543 0 01-.362.13H8.54a.543.543 0 01-.362-.13l-.01-.009zm.115 8.885l-.01-.01a.543.543 0 01-.129-.363V7.013a.49.49 0 01.14-.358.513.513 0 01.371-.153h.692c.139 0 .264.05.358.153a.468.468 0 01.153.358v6.825c0 .144-.052.271-.154.373a.49.49 0 01-.357.139h-.692a.543.543 0 01-.362-.13l-.01-.01z" fill="#35375B" opacity=".6"/></svg>
                                <span class="ml-3 call-share-label">
                                    {{ staticText.aboutShareLabel }}
                                </span>
                            </div>
                            <i v-if="showAboutShare" :id="'chevron-icon-202408081240'"
                                class="fas fa-chevron-up"
                            />
                            <i v-else :id="'chevron-icon-202408081240'"
                                class="fas fa-chevron-down"
                            />
                        </div>
                        <b-collapse v-model="showAboutShare">
                            <div v-for="(item, index) in shareItemsDettails" :key="index" class="about-item d-flex align-item-center"
                                :class="{'mt-0': index==0, 'pb-3': index==shareItemsDettails.length-1}"
                            >
                                <div class="about-item-icon d-flex">
                                    <img
                                        :src=getItemIcon(item.icon)
                                        height="20"
                                        width="20"
                                        svg-inline
                                    />
                                </div>
                                <span class="ml-2 about-item-text d-flex align-items-center">{{ item.title }}</span>
                            </div>
                        </b-collapse>
                    </div>
                    <div class="copy-share mt-3  mb-4">
                        <div class="d-flex justify-content-between mx-4 py-2">
                            <div class="d-flex flex-column">
                                <span class="link-label pb-1">Link</span>
                                <span class="link-url">{{callUrl}}</span>
                            </div>
                            <div id="copy-btn-202408081240"
                                class="copy-btn d-flex align-items-center"
                                @click="copyCallUrl()"    
                            >
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'cursor-pointer'" v-bind:svg-inline="''" v-bind:height="'20'" v-bind:width="'20'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.85 12.5A2.553 2.553 0 013.3 9.95V3.8H2.25C1.34 3.8.6 4.54.6 5.45v8.1c0 .91.74 1.65 1.65 1.65h7.5c.91 0 1.65-.74 1.65-1.65V12.5H5.85z" fill="#2A2D52" fill-opacity=".8"/><path d="M13.8 2.45A1.65 1.65 0 0012.15.8h-6.3A1.65 1.65 0 004.2 2.45v7.5c0 .911.739 1.65 1.65 1.65h6.3a1.65 1.65 0 001.65-1.65v-7.5z" fill="#2A2D52" fill-opacity=".8"/></svg>
                            </div>
                            <b-tooltip
                                target="copy-btn-202408081240"
                                :title="isCallCopied ? staticText.copiedCallLabel: staticText.copyCallLabel"
                                :delay="{ show: 100, hide: 400 }"
                                triggers="hover"
                            ></b-tooltip>
                        </div>
                    </div>
                    <div class="horizontal-divider"></div>
                    <div class="share-with mb-3 mt-4">
                        
                        <BaoVueMultiSelect v-model="selectedUsers"
                                    :options="teamMembers"
                                    :show-labels="false"
                                    :closeOnSelect="true"
                                    :track-by="'email'"
                                    :label="'full_name'"
                                    :placeholder="staticText.selectUserLabel"
                                    :multiple="true"
                        >
                        <template #placeholder>
                            <div v-show="!selectedUsers.length" class="d-flex multiselect__single">
                                <img src="@/assets/svgs/participants.svg" svg-inline>
                                <span class="ml-2 link-color">
                                {{ staticText.selectUserLabel }}
                                </span>
                            </div>
                        </template>
                        <template #selection="{ values, search, isOpen }">
                            <div v-show="!isOpen && values.length">
                                <div class="d-flex multiselect__single">
                                    <img src="@/assets/svgs/participants.svg" svg-inline>
                                    <span class="ml-2 link-color">
                                        {{staticText.selectUserLabel}}
                                    </span>
                                </div>
                            </div>
                        </template>

                    </BaoVueMultiSelect>
                    </div>
                    <div v-if="selectedUsers && selectedUsers.length" class="my-3 d-flex flex-column">
                        <span class="share-with-label mb-4">
                            {{staticText.shareWithLabel}}
                        </span>
                        <div class="d-flex shared-with">
                            <bao-pill v-for="(item, idx) in selectedUsers"
                                :key="idx"
                                :title="item.full_name"
                                :id="item.email"
                                @close="removeItem($event)"
                            />
                        </div>
                    </div>
                </div>
              </template>
              <template v-slot:modal-footer>
                <div class="w-100 d-flex justify-content-center">
                    <b-button class="btn btn-primary mr-3" size="sm" @click="closeShareModal">
                        {{staticText.cancelShareLabel}}
                    </b-button>
                    <b-button variant="primary" size="sm" @click="shareCall" :disabled="!checkForObjPermissionChanges">
                        {{staticText.saveShareLabel}}
                    </b-button>
                </div>
              </template>
        </b-modal>
        
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"

import BaseSelection from "@/apps/base/BaseSelection"
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import BaoPill from "@/apps/base/BaoPill"
import axios from "axios"

export default {
    name: 'ShareCallFeedbackModal',
    components: {
        BaseSelection,
        BaoVueMultiSelect,
        BaoPill
    },
    props: {
        callId:{
            type: Number,
            required: true
        }
    },
    data(){
        return {
            sharedWithUsers: [],
            showModal: true,
            teamMembers: [],
            showAboutShare: false,
            selectedUsers: [],
            isCallCopied: false,
            staticTextDefault: {
                copyCallLabel: 'Copy Link',
                copiedCallLabel: 'Link Copied',
                selectUserLabel: 'Select User',
                cancelShareLabel: 'Cancel',
                saveShareLabel: 'Save',
                shareWithLabel: 'Shared With',
                aboutShareLabel: 'What is shared?',
                recordedConversation: 'Recording of the Meeting',
                speechRecognitionMetrics: 'Speech Analysis Metrics',
                aiTranscript: 'AI Transcript',
                aiSummaries: 'AI Summaries',
                clickHistory: 'Playbook/Call Documentation',
                shareCallLabel: 'Share Call Details'
            }
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/user",
            callDetails: "callSummaryStore/getCallDetails"
        }),
        staticText () {
            return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
        },
        callUrl(){
            const fullCallUrl = window.location.href
            // truncate the url if it exceed 50 characters. on sharing call, some utm params are added and that makes the url length long, so we need to truncate there
            if(fullCallUrl.length>=50){
                return fullCallUrl.substr(0, 50)+'...'
            }
            return fullCallUrl
        },
        shareItemsDettails() {
            return [
                {
                    title: this.staticText.recordedConversation,
                    icon: 'play-icon-2'
                },
                {
                    title: this.staticText.speechRecognitionMetrics,
                    icon: 'microphone'
                },
                {
                    title: this.staticText.aiTranscript,
                    icon: 'transcript-2'
                },
                {
                    title: this.staticText.aiSummaries,
                    icon: 'ai-tool'
                },
                {
                    title: this.staticText.clickHistory,
                    icon: 'hand-pointer'
                }
            ]
        },
        checkForObjPermissionChanges() {
            const sharedWithUserIds = this.sharedWithUsers.map(user => user.pk)
            const newShareWithUserIds = this.selectedUsers.map(user => user.pk)
            const grantPermissionTo = this.selectedUsers.filter(user => !sharedWithUserIds.includes(user.pk))
            const revokePermissionFrom = this.sharedWithUsers.filter(user => !newShareWithUserIds.includes(user.pk))
            return Boolean(grantPermissionTo.length || revokePermissionFrom.length)
        }
    },
    methods: {
        ...mapActions({
            fetchCallDetails: "callSummaryStore/fetchCallDetails"
        }),
        closeShareModal(){
            this.showModal = false
        },
        async copyCallUrl(){
            await navigator.clipboard.writeText(window.location.href)
            this.isCallCopied = true
            await this.sleep(1000)
            this.isCallCopied = false
        },
        getSelectedUsers () {
            return this.callDetails.shared_with.users.filter(user => user.pk !== this.currentUser.pk && user.is_admin==false)
        },
        async shareCall(){
            const sharedWithUserIds = this.sharedWithUsers.map(user => user.pk)
            const newShareWithUserIds = this.selectedUsers.map(user => user.pk)
            const grantPermissionToids = this.selectedUsers.filter(user => !sharedWithUserIds.includes(user.pk)).map(user => user.pk)
            const revokePermissionFromIds = this.sharedWithUsers.filter(user => !newShareWithUserIds.includes(user.pk)).map(user => user.pk)
            const data = {
                grant_perm_to_user_ids: grantPermissionToids,
                remove_perm_from_user_ids: revokePermissionFromIds,
                call_url: window.location.href
            }
            try {
                await axios.put(`/api/calls/${this.callId}/share`, data)
            } catch(err){
                this.showGlobalToast({
                    status: "error",
                    message: { title: "Something went wrong", description: "Error in sharing the call data." }
                })
            }
            // get the refreshed call data
            await this.fetchCallDetails({ callId: this.callId })
            this.showModal = false
        },
        getItemIcon(icon){
            return require(`@/assets/svgs/${icon}.svg`)
        },
        removeItem(data){
            this.selectedUsers = this.selectedUsers.filter(item => item.email!==data)
        },
        async getGroupMembers(){
            const url = `/api/users/get_group_members?is_active=true&id!=${this.currentUser.pk}`
            const response = await axios.get(url)
            this.teamMembers = response.data && response.data.results ? response.data.results : response.data
            this.teamMembers = this.teamMembers.filter(user => user.is_admin==false)
        }
    },
    async mounted(){
        this.getGroupMembers()
        this.sharedWithUsers = this.getSelectedUsers()
        this.selectedUsers = this.sharedWithUsers
    }
}

</script>
<style lang="scss" scoped>

.about-share{
    background-color: #F0F0F0;
    border-radius: 12px;
}

.share-with{
    box-shadow: 4px 4px 20px 0px #0000001A;
    border-radius: 12px;
}
.shared-with{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.horizontal-divider {
    border: 1px solid #F0F0F0;
}

.link-label{
    font-size: 12px;
    line-height: 14.4px;
    color: #AAABBA
}
.link-url{
    font-size: 16px;
    line-height: 19.2px;
    color: #2A2D52;
}
.copy-share{
    box-shadow: 4px 4px 20px 0px #0000000D;
    border-radius: 12px;
}
.call-share-label{
    font-size: 16px;
    line-height: 19.2px;
    color: #555775;
}
.call-share-item{
    font-size: 16px;
    line-height: 19.2px;
    color: #7F8197;
}

.share-with-label{
    color: #7F8197;
}
.about-item{
    margin-left: 36px;
    margin-top: 12px;
}

.link-color{
    color: #2A2D52
}

.copy-btn{
    svg {
        path {
          fill: #AAABBA;
        }
    }
}
.about-icon {
    svg {
        path {
            fill: #000000;
        }
    }
}
.about-item-text{
    color: #7F8197;
    font-size: 16px;
    line-height: 19.2px;
}
.about-item-icon{
    svg {
        path {
            fill: #7F8197;
        }
    }
}
</style>